<!-- Tabs -->
<template>
    <div class="flex items-center w-full border-b border-gray-200">
        <div class="py-1.5 px-2 bg-gray-100 border-t border-r border-gray-200 text-sm first:border-l sm:py-2 sm:px-4 sm:text-base"
             :class="{ 'bg-white': currentStep === 1 }"
             v-if="!userStore.user || !userStore.user.webconnectid">
            <span class="sm:hidden"
                  :class="{ 'hidden' : currentStep === 1}"
            >
                Acc...
            </span>
            <span class="sm:!block"
                  :class="{ 'hidden' : currentStep !== 1 }">
                Account
            </span>
        </div>
        <div class="py-1.5 px-2 bg-gray-100 border-t border-r border-gray-200 text-sm first:border-l sm:py-2 sm:px-4 sm:text-base"
             :class="{ 'bg-white': currentStep === 2 }">
            <span class="sm:hidden"
                  :class="{ 'hidden' : currentStep === 2}"
            >
                Dat...
            </span>
            <span class="sm:!block"
                  :class="{ 'hidden' : currentStep !== 2 }">
                Datum/locatie
            </span>
        </div>
        <div class="py-1.5 px-2 bg-gray-100 border-t border-r border-gray-200 text-sm first:border-l sm:py-2 sm:px-4 sm:text-base"
             :class="{ 'bg-white': currentStep === 3 }"
             v-if="userStore.userIsCommercialCustomer">
            <span class="sm:hidden"
                  :class="{ 'hidden' : currentStep === 3}"
            >
                Med...
            </span>
            <span class="sm:!block"
                  :class="{ 'hidden' : currentStep !== 3 }">
                Medewerkers
            </span>
        </div>
        <div class="py-1.5 px-2 bg-gray-100 border-t border-r border-gray-200 text-sm first:border-l sm:py-2 sm:px-4 sm:text-base"
             :class="{ 'bg-white': currentStep === 4 }">
            <span class="sm:hidden"
                  :class="{ 'hidden' : currentStep === 4}"
            >
                Con...
            </span>
            <span class="sm:!block"
                  :class="{ 'hidden' : currentStep !== 4 }">
                Controleren
            </span>
        </div>
    </div>
</template>

<script setup lang="ts">
import {defineProps} from "vue";
import {useUserStore} from "../../../Stores/userStore";
const userStore = useUserStore();

interface PropsInterface {
      currentStep: number,
}

const props = defineProps<PropsInterface>()
</script>