import { reactive } from 'vue';
import {useCartStore} from '../Stores/cartStore';
import {useProductStore} from '../Stores/productStore';
import {VariantInterface} from "../Interfaces/VariantInterface";
import {ProductInterface} from "../Interfaces/ProductInterface";

export function ShoppingApi() {
    const cartStore = useCartStore();
    const productStore = useProductStore();

    function addToCart(variantId, qty: number = 1) {
        return cartStore.addToCart(variantId, qty);
    }

    async function addToCartWithModalByIds(variantId: number, productId: number, qty: number = 1) {
        try {
            const reactiveFetchedVariant : VariantInterface = <VariantInterface>reactive(
                await productStore.fetchVariantById(variantId)
            );

            const reactiveFetchedProduct : ProductInterface = <ProductInterface>reactive(
                await productStore.fetchProductById(productId)
            );

            addToCartWithModal(reactiveFetchedVariant, reactiveFetchedProduct, qty);
        } catch (error) {
            console.log('error getting variant or product');
            console.log(error);
            console.error(error);
        }
    }

    function addToCartWithModal(variant: VariantInterface, product: ProductInterface, qty: number = 1) {
        history.pushState(
            {},
            null,
            constructUrl(variant, product, qty).href
        );

        cartStore.cartModal = true;

        cartStore.cartModalContent = {
            variant: variant,
            selectedVariant: variant,
            product: product,
            qty: qty,
            hasExecutionsWithDate: product?.variants.some((execution) => {
                if (execution?.executionStartDate || execution?.executionEndDate) {
                    return true;
                }
            }),
        }


        console.debug('cart modal opened', cartStore.cartModal);
        console.debug('cart modal content', cartStore.cartModalContent);
    }

    function constructUrl(variant : VariantInterface, product : ProductInterface, qty : number) {
        const url = new URL(window.location.href);
        url.searchParams.set('m', 'add-to-cart');
        url.searchParams.set('var', String(variant.id));
        url.searchParams.set('prod', String(product.id));
        if(qty !== undefined){
            url.searchParams.set('qty', String(qty));
        }

        return url;
    }

    function closeAndClearCartModal() {
        let newUrl = removeParamsFromUrl();

        history.pushState(
            {},
            null,
            newUrl
        );

        clearCartModal();
    }

    function removeParamsFromUrl() {
        const url = new URL(window.location.href);
        url.searchParams.delete('m');
        url.searchParams.delete('var');
        url.searchParams.delete('prod');
        url.searchParams.delete('qty');

        return url;
    }

    function clearCartModal() {
        cartStore.cartModal = false;
        cartStore.cartModalContent = null;

        console.debug('cleared modal');
    }

    return {
        addToCart,
        clearCartModal,
        closeAndClearCartModal,
        addToCartWithModal,
        addToCartWithModalByIds,
    }
}
