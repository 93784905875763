import {defineStore} from 'pinia'
import {onMounted, ref, inject} from "vue";
import {Axios} from "axios";
import {CartInterface, DefaultCart} from "../Interfaces/CartInterface";
import {CartModalContent} from "../Interfaces/CartModalContent";

export const useCartStore = defineStore('cart', () => {
    const $axios: Axios = inject('$axios');

    const cart = ref<CartInterface>(DefaultCart());
    const errors = ref({});
    const notices = ref<{}>({});

    const cartModal = ref<boolean>(false);
    const cartModalContent = ref<CartModalContent | null>(null);

    const addToCart = (variantId, qty = 1, options: Array<any> = []) => {
        return $axios.post('/actions/_shop-plugin/cart/add-to-cart', {
            purchasableId: variantId,
            qty: qty,
            options: options
        }).then((res) => {
            console.debug(res);
            notices.value = res.data.notices;
            cart.value = res.data.cart;
        }).catch((err) => {
            errors.value = err.response.data.errors;
        });
    };

    const updateCart = () => {
        console.debug('updating cart with values', cart.value);
        return $axios.post('/actions/_shop-plugin/cart/update-cart', cart.value).then((res) => {
            console.debug('response cart with values', res.data.cart);
            //no errors, set new cart.
            cart.value = res.data.cart;
            errors.value = {};
            notices.value = res.data.notices;
        }).catch((err) => {
            console.error(err.response.data);
            errors.value = err.response.data.errors;
        });
    }

    const getCart = () => {
        return $axios.get('/actions/_shop-plugin/cart/get-cart', {}).then(function (response) {
            cart.value = response.data.cart;
        }).catch(function (error) {
            console.error(error);
        });
    };

    onMounted(getCart);

    return {
        addToCart,
        updateCart,
        getCart,
        cart,
        errors,
        notices,
        cartModal,
        cartModalContent,
    };
})