<template>
    <div class="form-group relative group"
         :class="getErrors && getErrors.length ? 'has-error' : ''"
    >
        <label :for="getElementId">
            {{ label }}
        </label>
        <input
            :id="getElementId"
            :type="type"
            :value="modelValue"
            :placeholder="placeholder ?? label"
            :autocomplete="autocomplete ?? 'off'"
            :required="required ?? false"
            ref="input"
            @input="$emit('update:modelValue', $event.target.value);"
        >
        <div class="error-message" v-if="getErrors && getErrors.length">
            <div v-for="error in getErrors" v-html="getErrors" />
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed} from 'vue';

let props = defineProps({
    modelValue: String|Number,
    type: {
        type: String,
        default: 'text'
    },
    label: String,
    placeholder: String,
    name: String,
    autocomplete: String,
    required: Boolean,
    errors: Object,
});

const getElementId = computed(() => {
    return 'input_' + props.label.split(' ').join('-').toLowerCase();
});

const getErrors = computed(() => {
    if (!props.errors) {
        return null;
    }

    for (const [key, value] of Object.entries(props.errors)) {
        if (key === props.name) {
            return value;
        }
    }
    return props.errors;
});

defineEmits(['update:modelValue']);
</script>
