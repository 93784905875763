<template>
    <div class="relative flex flex-col gap-4 w-full">
        <!-- Header -->
        <h3 class="heading-2" v-if="cartStore.errors.length">
            Toegevoegd aan je winkelwagen
        </h3>

        <template v-for="errors in cartStore.errors">
            <template v-for="error in errors">
                <FailureMessage :message="error"/>
            </template>
        </template>

        <!-- Body -->
        <div class="flex flex-col items-center gap-4 sm:flex-row">
            <div class="self-start shrink-0 w-full border border-gray-200 sm:w-24 lg:w-32" v-html="cartStore.cartModalContent.variant['image']"/>
            <div class="flex flex-col w-full sm:gap-2">
                <h3 v-show="cartStore.cartModalContent.variant.title.length > 0"
                    class="leading-6 font-medium text-lg sm:text-xl"
                    v-text="cartStore.cartModalContent.variant.title">
                </h3>
<!--                <span class="text-sm line-clamp-2" v-html="cartStore.cartModalContent.product.shortDescription"/>-->
                <span class="font-heading font-extrabold text-xl md:text-2xl"
                      v-text="cartStore.cartModalContent.variant.salePriceAsCurrency"/>
            </div>
        </div>

        <div class="flex flex-col items-center gap-2 pt-4 border-t border-gray-200 sm:flex-row">
            <button class="button button--secondary w-full whitespace-nowrap ring-0 sm:w-auto" type="button"
                    name=""
                    @click.prevent="closeAndClearCartModal">
                Verder winkelen
            </button>

            <template v-if="cartStore.cartModalContent.variant.stock || cartStore.cartModalContent.variant.hasUnlimitedStock">
                <a
                    class="button button--primary w-full whitespace-nowrap ml-auto sm:w-auto"
                    :href="urlStore.getUrl('cart_url')">
                    Naar winkelwagen
                </a>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import {ShoppingApi} from "../../Composables/ShoppingApi";
import {useCartStore} from "../../Stores/cartStore";
import {onMounted} from "vue";
import {useUrlStore} from "../../Stores/urlStore";
import FailureMessage from "../Forms/FailureMessage.vue";

const {addToCart, closeAndClearCartModal} = ShoppingApi()
const cartStore = useCartStore();
const urlStore = useUrlStore();

onMounted(() => {
    cartStore.errors = [];
    addToCart(cartStore.cartModalContent.variant.id, cartStore.cartModalContent.qty)
});
</script>