<template>
    <div v-if="currentStep === 3" class="w-full">
        <!-- Add participants -->
        <div class="flex flex-col w-full">
            <h4 class="heading-4 mb-4 pb-4 border-b border-gray-200">
                Medewerkers
            </h4>

            <div class="pb-4 mb-4 border-b border-gray-200">
                <h5 class="heading-5 mb-2" v-if="cartStore.cartModalContent.companyUsers?.length">
                    Toegevoegde medewerkers
                </h5>

                <div class="flex flex-wrap gap-x-4 gap-y-2">
                    <div class="relative flex flex-col py-2 pl-4 pr-7 border-2 border-gray-200"
                         v-for="(selectedCompanyUser, index) in cartStore.cartModalContent.companyUsers"
                         v-if="cartStore.cartModalContent.companyUsers?.length">
                        <span class="text-sm font-medium">
                            {{ selectedCompanyUser.firstName }} {{ selectedCompanyUser.lastName }}
                        </span>
                        <span class="text-xs">{{ selectedCompanyUser.email }}</span>
                        <button class="absolute top-0 right-0 flex items-center justify-center w-4 h-4 bg-red-100 text-red-600 transition-colors hover:text-red-700 hover:bg-red-200"
                                @click.prevent="removeCompanyUser(index)">
                            <icon-close class="w-2 h-2 fill-current rounded-bl"/>
                        </button>
                    </div>
                    <div v-else class="flex items-start gap-2 font-normal italic text-sm">
                        <icon-warning class="w-4 h-4 mt-0.5 shrink-0 fill-current"/>
                        U heeft nog geen medewerkers toegevoegd.
                    </div>
                </div>
            </div>

            <div class="flex flex-wrap gap-x-4 gap-y-2 pb-4 mb-4 border-b border-gray-200" v-if="userStore.organisation?.companyUsers?.length">
                <h5 class="w-full heading-5">
                    Selecteer medewerkers uit uw organisatie
                </h5>

                <div ref="inputElement" class="relative w-full max-w-full">
                    <input
                        class="w-full max-w-full h-11 py-2 pl-3 pr-8 border border-gray-300 outline-none text-sm text-inherit focus:border-transparent focus:ring-2 focus:outline-none focus:ring-gray-200"
                        :class="{ 'rounded-b-none ring-2 ring-gray-200': companyUsersDropdownIsOpen }"
                        type="search"
                        placeholder="Zoek op naam of e-mailadres..."
                        v-model="searchCompanyUsers"
                        @focusin="openCompanyUsersDropdown"
                    />
                    <icon-magnifying-glass v-show="!companyUsersDropdownIsOpen"
                                           class="absolute z-10 top-3.5 right-3.5 w-3.5 h-3.5 text-gray-600 pointer-events-none"/>
                    <icon-close v-show="companyUsersDropdownIsOpen" @click.prevent="companyUsersDropdownIsOpen = false"
                                class="absolute z-10 top-3.5 right-3.5 w-3.5 h-3.5 text-gray-600 cursor-pointer"/>
                    <div v-show="companyUsersDropdownIsOpen"
                         class="absolute z-10 top-11 left-0 mt-0.5 w-full max-h-44 overflow-y-auto rounded-b-md bg-white ring-2 ring-gray-200">
                        <ul v-show="userStore.organisation?.companyUsers.length">
                            <template v-for="(companyUser) in availableCompanyUsersToSelect">
                                <li class="transition-colors cursor-pointer border-t border-light first:border-none">
                                    <button class="relative w-full flex gap-2 items-center py-2 px-4 font-normal transition-colors"
                                            :class="isInCartModelContentCompanyUsers(companyUser) ? 'bg-light hover:bg-light cursor-default opacity-50' : 'bg-white hover:text-secondary'"
                                            @click.prevent="addCompanyUser(companyUser);"
                                    >
                                        <span class="flex-col flex text-left">
                                            <span class="text-sm font-semibold">
                                                {{ companyUser.firstName }} {{ companyUser.lastName }}
                                            </span>
                                            <span class="text-xs font-light">
                                                {{ companyUser.email }}
                                            </span>
                                        </span>
                                    </button>
                                </li>
                            </template>
                        </ul>

                        <div v-show="!Object.keys(availableCompanyUsersToSelect).length && !searchCompanyUsers.length"
                             class="px-4 py-2 font-normal text-sm italic">
                            Geen resultaten gevonden
                        </div>
                        <div v-show="!Object.keys(availableCompanyUsersToSelect).length && searchCompanyUsers.length > 0"
                             class="px-4 py-2 font-normal text-sm italic">
                            Geen resultaten gevonden voor "{{ searchCompanyUsers }}"
                        </div>

                    </div>
                </div>
            </div>

            <div class="flex flex-col w-full gap-y-4">
                <button class="button button--sm" @click.prevent="showAddUserForm = !showAddUserForm">
                    <template v-if="showAddUserForm">
                        <icon-close class="w-4 h-4"/>
                        Nieuwe Medewerker annuleren
                    </template>
                    <template v-else>
                        <icon-plus class="w-4 h-4"/>
                        Nieuwe Medewerker toevoegen
                    </template>
                </button>

                <template v-if="showAddUserForm">
                    <OrganisationUserForm @companyUserAddedOrUpdated="(newOrCurrentCompanyUser) => addCompanyUserAndRefresh(newOrCurrentCompanyUser)"/>
                </template>
            </div>

            <div class="flex flex-col items-center gap-2 w-full mt-4 pt-4 border-t border-gray-200 sm:flex-row">
                <button class="button button--secondary w-full sm:w-auto" @click.prevent="closeAndClearCartModal">
                    Annuleren
                </button>

                <div class="ml-auto flex gap-2 w-full ml-auto flex flex-col gap-2 sm:flex-row">
                    <button class="button button--primary w-full whitespace-nowrap ring-0 ml-auto sm:w-auto" type="button"
                            @click.prevent="emit('updateCurrentStep', 2)">
                        Vorige
                    </button>

                    <button class="button button--primary w-full whitespace-nowrap ring-0 auto sm:w-auto" type="button"
                            :class="cartStore.cartModalContent.companyUsers?.length ? '' : 'opacity-50'"
                            @click.prevent="cartStore.cartModalContent.companyUsers?.length && emit('updateCurrentStep', 4)"
                    >
                        Volgende
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, defineEmits, defineProps, inject, onMounted, ref} from "vue";
import IconClose from "../../../Icons/IconClose.vue";
import OrganisationUserForm from "../../Account/OrganisationUserForm.vue";
import {ShoppingApi} from "../../../Composables/ShoppingApi";
import {useUserStore} from "../../../Stores/userStore";
import {useCartStore} from "../../../Stores/cartStore";
import IconPlus from "../../../Icons/IconPlus.vue";
import IconMagnifyingGlass from "craft-news-plugin/src/resources/js/Vue/Icons/IconMagnifyingGlass.vue";
import IconWarning from "../../../Icons/IconWarning.vue";

interface PropsInterface {
    currentStep: number,
}

const cartStore = useCartStore();
const userStore = useUserStore();
const props = defineProps<PropsInterface>()
const emit = defineEmits(['updateCurrentStep']);
const {closeAndClearCartModal, addToCart} = ShoppingApi()
const showAddUserForm = ref<boolean>(false);

const searchCompanyUsers = ref('');
const companyUsersDropdownIsOpen = ref();
const inputElement = ref(null)

onMounted(async () => {
    await userStore.getUserGroups();

    if (!userStore.userIsCommercialCustomer) {
        emit('updateCurrentStep', 4)
    }

    try {
      await userStore.getOrganisationDetails()
    } catch (e) {
      // Do nothing
    }

    if (!cartStore.cartModalContent.companyUsers?.length) {
        cartStore.cartModalContent.companyUsers = [];
    }
});

const addCompanyUserAndRefresh = (companyUser) => {
    addCompanyUser(companyUser);
    showAddUserForm.value = false;
}

const addCompanyUser = (newCompanyUser) => {
    if (!isInCartModelContentCompanyUsers(newCompanyUser)) {
        cartStore.cartModalContent.companyUsers.push(newCompanyUser);
    }
}

const removeCompanyUser = (index) => {
    cartStore.cartModalContent.companyUsers.splice(index, 1);
}

const isInCartModelContentCompanyUsers = (companyUserToCheck) => {
    return cartStore.cartModalContent.companyUsers?.some((companyUser) => {
        if (companyUser.id === companyUserToCheck.id) {
            return true;
        }
    })
}

const availableCompanyUsersToSelect = computed(function () {
    let users = userStore.organisation?.companyUsers;

    if (!users) {
        return [];
    }

    users = users.filter((companyUser) => {
        return !isInCartModelContentCompanyUsers(companyUser);
    });

    if (searchCompanyUsers.value.length > 0) {
        users = users.filter((companyUser) => {
            return companyUser.firstName?.toLowerCase().includes(searchCompanyUsers.value.toLowerCase()) ||
                companyUser.lastName?.toLowerCase().includes(searchCompanyUsers.value.toLowerCase()) ||
                companyUser.email?.toLowerCase().includes(searchCompanyUsers.value.toLowerCase());
        });
    }

    return users;
});

function closeCompanyUsersDropdown() {
    companyUsersDropdownIsOpen.value = false;
}

function openCompanyUsersDropdown() {
    if (companyUsersDropdownIsOpen.value) {
        return;
    }
    companyUsersDropdownIsOpen.value = true;
}

window.addEventListener('click', (event) => {
    if(inputElement.value === null) {
        return;
    }
    if (companyUsersDropdownIsOpen.value && !inputElement.value.contains(event.target)) {
        closeCompanyUsersDropdown();
    }
});
</script>