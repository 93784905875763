import {defineStore} from 'pinia'
import {inject, ref} from "vue";
import {Axios} from "axios";

export const useUrlStore = defineStore('urls', () => {
    const urls = ref(window.URLS);
    const $axios: Axios = inject('$axios');
    const errors = ref({});
    const notices = ref<{}>({});
    const addUrl = (key: string, url: string) => {
        urls[key] = url;
    }

    const getUrl = (key: string) => {
        return urls.value[key] ?? null;
    }

    async function getHashedUrl(currentUrl: string) : Promise<string> {
        const result = await $axios.post('/actions/_shop-plugin/account/get-hashed-redirect', {
            url: currentUrl
        });
        notices.value = result.data.notices;
        return result.data.hashedRedirect;
    }

    return {
        getHashedUrl,
        urls,
        addUrl,
        getUrl
    };
})