import {AddressInterface, DefaultAddress} from "./AddressInterface";
import {LineItemInterface} from "./LineItemInterface";

export interface CartInterface {
    number: null | string,
    couponCode: null | string,
    email: null | string,
    billingAddressSameAsShipping: boolean,
    billingAddress?: AddressInterface,
    shippingAddress?: AddressInterface,
    //source is the address id from the other object, addresses get cloned to the cart
    sourceBillingAddressId?: number | null,
    billingAddressId?: number | null,
    //source is the address id from the other object, addresses get cloned to the cart
    sourceShippingAddressId?: number | null,
    shippingAddressId?: number | null,
    shippingMethodHandle: null | string,
    shippingMethodName: null | string
    lineItems: LineItemInterface[],
    itemSubtotal: string,
    itemTotalAsCurrency: string,
    totalAsCurrency: string,
    total: string,
    saleDiscount: number,
    hasSaleDiscount: boolean,
    shippingCosts: number,
    shippingCostsAsCurrency: string,
    availableShippingMethodOptions: Object,
    couponDiscount: string,
    fields: {
        orderNote: string
    },
    totalQty: number,
    hasCoupon: boolean,
    tax: number
    //clear notices when you update cart
    clearNotices: boolean
}

export const DefaultCart = () => {
    return {
        number: null,
        couponCode: null,
        email: null,
        billingAddressSameAsShipping: true,
        shippingMethodHandle: null,
        shippingMethodName: null,
        lineItems: [],
        itemSubtotal: '',
        itemTotalAsCurrency: '',
        totalAsCurrency: '',
        total: '',
        shippingCosts: 0.00,
        shippingCostsAsCurrency: '&euro; 0,00',
        availableShippingMethodOptions: {},
        couponDiscount: '',
        totalQty: 0,
        saleDiscount: 0,
        hasSaleDiscount: false,
        hasCoupon: false,
        tax: 0,
        clearNotices: true,
        fields: {
            orderNote: ''
        }
    }
}
