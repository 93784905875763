import {defineStore} from 'pinia'
import {inject} from "vue";
import {Axios} from "axios";
import {VariantInterface} from "../Interfaces/VariantInterface";
import {ProductInterface} from "../Interfaces/ProductInterface";

export const useProductStore = defineStore('products', () => {
    const $axios: Axios = inject('$axios');

    async function fetchVariantById(variantId: number) : Promise<VariantInterface> {
        try {
            const response = await $axios.get(`/actions/_shop-plugin/product/get-variant-by-id`, {
                params: {variantId}
            });
            return response.data as VariantInterface;
        } catch (error) {
            console.error('Error fetching variant:', error);
            return error;
        }
    }

    async function fetchProductById(productId: number) : Promise<ProductInterface> {
        try {
            const response = await $axios.get(`/actions/_shop-plugin/product/get-product-by-id`, {
                params: { productId }
            });
            return response.data as ProductInterface;
        } catch (error) {
            console.error('Error fetching product:', error);
        }
    }

    return {
        fetchVariantById,
        fetchProductById,
    };
})