<template>
    <!-- Add to cart modal for Vue -->
    <div v-if="cartStore.cartModal" class="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title"
         role="dialog"
         aria-modal="true"
    >
        <div class="flex items-end justify-center min-h-screen px-4 text-center md:items-center sm:block sm:p-0">
            <div v-cloak @click="closeAndClearCartModal" v-show="cartStore.cartModal"
                 class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-40 backdrop-blur-sm" aria-hidden="true"
            ></div>

            <!-- Books -->
            <!-- --------------------------------------------------------------------------------------------------- -->
            <div v-cloak v-if="cartStore.cartModal && cartStore.cartModalContent.product.type === 'books'"
                 class="inline-flex flex-col gap-4 w-full max-w-[90vw] p-6 my-20 overflow-hidden font-normal text-left transition-all transform bg-white shadow-xl sm:max-w-[70vw] sm:p-8 xl:max-w-4xl"
                 @keydown.enter.prevent
            >
                <!-- Close button -->
                <close-add-to-cart-modal-button/>
                <add-to-cart-modal-book :variant="cartStore.cartModalContent.variant"/>
            </div>

            <!-- Modules -->
            <!-- --------------------------------------------------------------------------------------------------- -->
            <div v-cloak v-if="cartStore.cartModal && cartStore.cartModalContent.product.type === 'practicemodules'"
                 class="inline-flex flex-col gap-4 w-full max-w-[90vw] p-6 my-20 overflow-hidden font-normal text-left transition-all transform bg-white shadow-xl sm:max-w-[70vw] sm:p-8 xl:max-w-4xl"
                 @keydown.enter.prevent
            >
                <!-- Close button -->
                <close-add-to-cart-modal-button/>
                <add-to-cart-modal-module :variant="cartStore.cartModalContent.variant"/>
            </div>

            <!-- Courses -->
            <!-- --------------------------------------------------------------------------------------------------- -->
            <div v-cloak v-if="cartStore.cartModal && cartStore.cartModalContent.product.type === 'courses'"
                 class="inline-flex flex-col gap-4 w-full max-w-[90vw] p-6 my-20 overflow-hidden font-normal text-left transition-all transform bg-white shadow-xl sm:max-w-[70vw] sm:p-8 xl:max-w-4xl"
                 @keydown.enter.prevent
            >
                <!-- Close button -->
                <close-add-to-cart-modal-button/>
                <add-to-cart-modal-course :step="1"/>
            </div>

        </div>
    </div>
</template>

<script setup lang="ts">
import {ShoppingApi} from "../../Composables/ShoppingApi";
import {useCartStore} from "../../Stores/cartStore";
import CloseAddToCartModalButton from "./Partials/CloseAddToCartModalButton.vue";
import AddToCartModalBook from "./AddToCartModalBook.vue";
import AddToCartModalModule from "./AddToCartModalModule.vue";
import AddToCartModalCourse from "./AddToCartModalCourse.vue";
import {useUserStore} from "../../Stores/userStore";
import {onMounted} from "vue";

const {closeAndClearCartModal, addToCart, addToCartWithModalByIds} = ShoppingApi()
const cartStore = useCartStore();
const userStore = useUserStore();

window.addEventListener('keydown', event => {
    if (event.key === 'Escape') {
        closeAndClearCartModal();
    }
});

function addProductToCart(variant, qty, showModal) {
    addToCart(variant, qty)

    if (!showModal) {
        closeAndClearCartModal()
    }
}

function openModalIfUrlContainsAddToCart() {
  const url = new URL(window.location.href);
  const method = url.searchParams.get('m');

  if(method === 'add-to-cart') {
    const variantId = parseInt(url.searchParams.get('var'));
    const productId = parseInt(url.searchParams.get('prod'));
    const qty = parseInt(url.searchParams.get('qty'));

    addToCartWithModalByIds(variantId, productId, qty);
    return true;
  }
  return false;
}

onMounted(() => {
  console.debug('onMounted')

  openModalIfUrlContainsAddToCart();
});
</script>