<template>
    <div class="relative flex flex-col gap-4 w-full">
        <!-- Header -->
        <h2 class="heading-2">
            Toevoegen aan je winkelwagen
        </h2>
        <!-- Body -->
        <form class="flex flex-col items-start gap-y-4">
            <div class="flex flex-col items-center gap-4 pb-4 border-b border-gray-200 sm:flex-row">
                <div class="self-start shrink-0 w-full border border-gray-200 sm:w-24 lg:w-32" v-html="cartStore.cartModalContent.variant['image']"/>
                <div class="flex flex-col w-full sm:gap-2">
                    <h3 v-show="cartStore.cartModalContent.variant.title.length > 0"
                        class="leading-6 font-medium text-lg sm:text-xl"
                        v-text="cartStore.cartModalContent.variant.title">
                    </h3>
                    <span class="text-sm line-clamp-2" v-html="removeHtmlTags(cartStore.cartModalContent.product.shortDescription)"/>
                    <span class="font-heading font-extrabold text-xl md:text-2xl"
                          v-text="cartStore.cartModalContent.variant['salePriceAsCurrency']"/>
                </div>
            </div>
            <h4 class="heading-4">
                Gegevens ELO account
            </h4>

            <div class="flex flex-col gap-4 w-full m-0 sm:gap-5 font-medium">
                <CustomInput v-model="form.email1"
                             :name="'email1'"
                             :label="'E-mailadres'"
                             :errors="form.emailErrors"
                />

                <CustomInput v-model="form.email2"
                             :name="'email2'"
                             :label="'E-mailadres herhalen'"
                             :errors="form.emailErrors"
                />
            </div>

            <div class="flex items-start gap-2 w-full p-4 flex gap-0.5 text-red-600 text-sm text-left border border-red-600 bg-red-50"
                 v-if="form.emailErrors.emailCompare"
            >
                <icon-waring class="shrink-0 inline-flex w-4 h-4 mt-0.5"/>
                <span>
                    De ingevoerde e-mailadressen komen niet overeen.
                </span>
            </div>

            <div class="flex items-start gap-2 w-full p-4 text-sm bg-gray-100 border border-gray-200 text-left">
                <icon-waring class="shrink-0 inline-flex w-4 h-4 mt-0.5"/>
                <span>
                    Als het e-mailadres niet klopt is er geen manier om contact op te nemen.
                </span>
            </div>

            <div class="flex flex-col items-center gap-2 w-full mt-2 pt-4 border-t border-gray-200 sm:flex-row">
                <button class="button button--secondary" @click.prevent="closeAndClearCartModal">
                    Annuleren
                </button>
                <button class="button button--primary w-full whitespace-nowrap ring-0 ml-auto sm:w-auto" type="button"
                        name=""
                        @click.prevent="validateAndAddToCart">
                    In winkelwagen plaatsen
                </button>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import IconWaring from "../../Icons/IconWarning.vue";
import {computed, ref, watch} from "vue";
import CustomInput from "../../Components/Forms/CustomInput.vue";
import {useUserStore} from "../../Stores/userStore";
import {useCartStore} from "../../Stores/cartStore";
import {ShoppingApi} from "../../Composables/ShoppingApi";
import {useHtmlHelper} from "../../Composables/useHtmlHelper";

const cartStore = useCartStore();
const {removeHtmlTags} = useHtmlHelper();
let defaultEloAddressComputed = computed(() => useUserStore().user?.defaultELOEmailAddress);
let defaultEloAddress = ref(null);
const {closeAndClearCartModal, addToCart} = ShoppingApi()

watch(defaultEloAddressComputed, (newVal) => {
    defaultEloAddress.value = newVal;
}, {immediate: true});

let form = ref({
    email1: defaultEloAddress ? defaultEloAddress : '',
    email2: '',
    emailErrors: {}
});

const validateAndAddToCart = () => {
    // Check if both email addresses are valid and the same
    !validEmailAddress(form.value.email1) ? Object.assign(form.value.emailErrors, {email1: ['U heeft geen geldig e-mailadres ingevoerd']}) : delete form.value.emailErrors.email1;
    !validEmailAddress(form.value.email2) ? Object.assign(form.value.emailErrors, {email2: ['U heeft geen geldig e-mailadres ingevoerd']}) : delete form.value.emailErrors.email2;
    form.value.email1 !== form.value.email2 ? Object.assign(form.value.emailErrors, {emailCompare: ['De e-mailadressen komen niet overeen']}) : delete form.value.emailErrors.emailCompare;

    // Add to cart if no errors
    if (Object.keys(form.value.emailErrors).length === 0) {
        try {
            let options = [{
                eloEmailAddress: form.value.email1
            }];

            cartStore.addToCart(cartStore.cartModalContent.variant.id, 1, options);
            closeAndClearCartModal()
        } catch (e) {

        }
    }

    return form.value.emailErrors.length;
}

const validEmailAddress = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}
</script>