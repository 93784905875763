<template>
    <div v-if="currentStep === 2" class="w-full">
        <h4 class="heading-4 mb-4 pb-4 border-b border-gray-200">
            Datum/locatie
        </h4>
        <div class="flex flex-col w-full gap-y-2" v-if="cartStore.cartModalContent.product.variants && cartStore.cartModalContent.product.variants.length">
            <label for="#course-date">Datum</label>
            <select id="course-date" class="w-full" v-model="selectedVariantId">
                <option v-for="execution in availableVariants"
                        :value="execution.id"
                        v-if="cartStore.cartModalContent.hasExecutionsWithDate"
                >
                    {{ dayjs(execution.executionStartDate?.date).format('DD-MM-YYYY') }} -
                    {{ dayjs(execution.executionEndDate?.date).format('DD-MM-YYYY') }} |
                    {{ dayjs(execution.executionStartTime?.date).format('HH:mm') }} t/m
                    {{ dayjs(execution.executionEndTime?.date).format('HH:mm') }} |
                    {{ execution.executionLocation }}
                </option>
            </select>
        </div>

        <div class="flex flex-col items-center gap-2 w-full mt-4 pt-4 border-t border-gray-200 sm:flex-row">
            <button class="button button--secondary w-full whitespace-nowrap ring-0 ml-auto sm:w-auto" @click.prevent="closeAndClearCartModal">
                Annuleren
            </button>

            <div class="w-full ml-auto flex flex-col gap-2 sm:flex-row">
                <button class="button button--primary w-full whitespace-nowrap ring-0 sm:ml-auto sm:w-auto" type="button"
                        v-if="!userStore.user"
                        @click.prevent="emit('updateCurrentStep', 1)">
                    Vorige
                </button>
                <button class="button button--primary w-full whitespace-nowrap ring-0 sm:ml-auto sm:w-auto" type="button"
                        :class="cartStore.cartModalContent.selectedVariant ? '' : 'opacity-50'"
                        @click.prevent="cartStore.cartModalContent.selectedVariant && emit('updateCurrentStep', 3)"
                >
                    Volgende
                </button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {defineEmits, defineProps, ref, watch, onMounted, computed} from "vue";
import {useCartStore} from "../../../Stores/cartStore";
import dayjs from "dayjs";
import {ShoppingApi} from "../../../Composables/ShoppingApi";
import {useUserStore} from "../../../Stores/userStore";

const {closeAndClearCartModal} = ShoppingApi()

interface PropsInterface {
    currentStep: number,
}

const cartStore = useCartStore();
const userStore = useUserStore();
const props = defineProps<PropsInterface>()
const emit = defineEmits(['updateCurrentStep']);
const selectedVariantId = ref();

const availableVariants = computed(() => {
  return cartStore.cartModalContent.product.variants.filter(variant => variant.stock >= 1)
});

const availableVariantIds = computed(() => {
  return availableVariants.value.map(variant => variant.id)
});

onMounted( async () => {
  selectedVariantId.value = cartStore.cartModalContent.variant?.id ?? availableVariantIds.value[0];
});

watch(selectedVariantId, (newValue) => {
  cartStore.cartModalContent.selectedVariant = cartStore.cartModalContent.product.variants.find(variant => variant.id === newValue)
});
</script>








