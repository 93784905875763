<template>
    <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
      <a :href="personalRegisterUrl" title="Particulier registreren" class="ring-0 button text-center button--quaternary gap-2 flex flex-col">
            <strong class="text-secondary">Persoonlijk registreren</strong>
            <span class="font-body text-sm">Ik schrijf mezelf in en betaal de rekening zelf.</span>
        </a>

        <a :href="businessRegisterUrl" title="Zakelijk registreren" class="ring-0 text-center button button--quaternary gap-2 flex flex-col">
            <strong class="text-secondary">Zakelijk registreren</strong>
            <span class="font-body text-sm">Ik schrijf mezelf en/of een ander in en de werkgever betaalt.</span>
        </a>
    </div>
</template>

<script setup lang="ts">
import {useUrlStore} from "../../../Stores/urlStore";
const urlStore = useUrlStore();

import {computed} from 'vue';

const personalRegisterUrl = computed(() => {
  return `${urlStore.getUrl('register_url')}?redirect=${encodeURIComponent(window.location.href)}`;
});

const businessRegisterUrl = computed(() => {
  return `${urlStore.getUrl('register_business_url')}?redirect=${encodeURIComponent(window.location.href)}`;
});
</script>