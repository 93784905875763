export function useHtmlHelper() {
    const removeHtmlTags = (html) => {
        if (html === null) {
            return '';
        }

        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent;
    }

    return {
        removeHtmlTags
    }
}