export interface UserInterface {
    id: number,
    username: string,
    firstName: string,
    lastName: string,
    friendlyName: string,
    fullName: string
    defaultELOEmailAddress: string,
    webconnectid: string,
    webconnectInitials: string,
    webconnectSex: string,
    webconnectDateOfBirth: string,
    webconnectPlaceOfBirth: string,
    webconnectPhoneNumber: string,
}

export const defaultUser = () => {
    return null;
}
