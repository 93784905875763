import {defineStore} from 'pinia'
import {onMounted, ref, inject} from "vue";
import {Axios} from "axios";
import {defaultUser, UserInterface} from "../Interfaces/UserInterface";
import {AddressInterface} from "../Interfaces/AddressInterface";
import collect from "collect.js";
import {OrderInterface} from "../Interfaces/OrderInterface";
import Swal from 'sweetalert2';
import {defaultUserGroups, UserGroupInterface} from "../Interfaces/UserGroupInterface";
import {OrganisationInterface} from "../Interfaces/OrganisationInterface";

export const useUserStore = defineStore('user-details', () => {
        const userIsLoggedIn = ref<boolean>(false);
        const user = ref<null | UserInterface>(defaultUser());
        const organisation = ref<null | OrganisationInterface>(null);
        const userGroups = ref<null | UserGroupInterface[]>(defaultUserGroups());
        const userIsCommercialCustomer = ref<boolean>(false);
        const billingAddresses = ref<AddressInterface[]>([]);
        const shippingAddresses = ref<AddressInterface[]>([]);
        const orders = ref<OrderInterface[]>([]);
        const courses = ref<Array<[]>>([]);
        const orderPagination = ref<Array<[]>>([]);
        const $axios: Axios = inject('$axios');
        const $collect: typeof collect = inject('$collect');
        const errors = ref<{ message: string } | null>(null);

        const setError = (message: string | null) => {
            errors.value = {message: message};
        }

        const loginUser = (loginName: string, password: string, redirect: string = null)  => {
            return $axios.post('/actions/users/login', {
                loginName: loginName,
                password: password,
                redirectUrl: redirect
            }).then(async (res) => {
                errors.value = null;
                userIsLoggedIn.value = true;

                await $axios
                    .get('/actions/users/session-info')
                    .then(response => {
                        // update CSRF token
                        $axios.defaults.headers.common['X-CSRF-Token'] = response.data.csrfTokenValue;
                    });
            }).catch((err) => {
                errors.value = err.response.data;
                userIsLoggedIn.value = false;
            });
        }
        const getUserDetails = () => {
            return $axios.get('/actions/_shop-plugin/account/get-user-details').then((res) => {
                if (res.data.user !== null) {
                    userIsLoggedIn.value = true;
                    user.value = res.data.user;
                }
            });
        }

        const getOrganisationDetails = () => {
            return $axios.get('/actions/_shop-plugin/business-account/get-organisation-details').then((res) => {
                if (res.data !== null) {
                    organisation.value = res.data;
                }
            });
        }

        const getUserGroups = () => {
            return $axios.get('/actions/_shop-plugin/account/get-user-groups').then((res) => {
                if (res.data !== null) {
                    userGroups.value = res.data;
                    userIsCommercialCustomer.value = userGroups.value.map((item) => item.handle).includes('commercialCustomers')
                }
            });
        }

        const getOrders = (paginationParams) => {
            return $axios.get('/actions/_shop-plugin/account/get-orders', {params: paginationParams, loader: true}).then((res) => {
                orders.value = res.data.orders;
                orderPagination.value = res.data.pagination;
            });
        }

        const getCoursesOfParticipant = () => {
            return $axios.get('/actions/_shop-plugin/account/get-courses-of-participant/').then((res) => {
                courses.value = res.data.courses;
            });
        }

        const addAddress = (address: AddressInterface) => {
            return $axios.post('/actions/_shop-plugin/account/save-address', address).then((res) => {
                errors.value = null;
                if (res.data.address.fields.addressType === 'billing') {
                    billingAddresses.value.push(res.data.address);
                }
                if (res.data.address.fields.addressType === 'shipping') {
                    shippingAddresses.value.push(res.data.address);
                }
            }).catch((err) => {
                errors.value = err.response.data.errors;
            });
        }

        const updateAddress = (address: AddressInterface) => {
            return $axios.post('/actions/_shop-plugin/account/save-address', address)
                .then((res) => {
                    errors.value = null;

                    if (res.data.address.fields.addressType === 'billing') {
                        $collect(billingAddresses.value).replace(res.data.address.addressId, res.data.address);
                    }
                    if (res.data.address.fields.addressType === 'shipping') {
                        $collect(billingAddresses.value).replace(res.data.address.addressId, res.data.address);
                    }
                }).catch((err) => {
                    console.debug(err);
                    errors.value = err.response.data.errors;
                });
        }

        const deleteAddress = (address: AddressInterface) => {
            Swal.fire({
                text: "Weet je zeker dat je dit adres wilt verwijderen?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ja',
                cancelButtonText: 'Nee'
            }).then((result) => {
                if (result.isConfirmed) {
                    return $axios.post('/actions/_shop-plugin/account/delete-address', address)
                        .then((res) => {
                            errors.value = null;

                            if (res.data.address.fields.addressType === 'billing') {
                                billingAddresses.value = $collect(billingAddresses.value).reject((value) => {
                                        return value.addressId === res.data.address.addressId;
                                    }
                                ).toArray();
                            }
                            if (res.data.address.fields.addressType === 'shipping') {
                                shippingAddresses.value = $collect(shippingAddresses.value).reject((value) => {
                                        return value.addressId === res.data.address.addressId;
                                    }
                                ).toArray();
                            }

                            Swal.fire({
                                icon: 'success',
                                text: 'Adres is verwijderd.'
                            })
                        }).catch((err) => {
                            console.debug(err);
                            errors.value = err.response.data.errors;
                        });
                }
            });
        }

        const getAddresses = () => {
            return $axios.get('/actions/_shop-plugin/account/get-addresses').then((res) => {
                if (Object.keys(res.data.billingAddresses).length) {
                    billingAddresses.value = res.data.billingAddresses;
                }
                if (Object.keys(res.data.shippingAddresses).length) {
                    shippingAddresses.value = res.data.shippingAddresses;
                }
            });
        }

        onMounted(getUserDetails);
        onMounted(getAddresses);
        onMounted(getUserGroups);

        return {
            userIsLoggedIn,
            user,
            organisation,
            userGroups,
            userIsCommercialCustomer,
            orders,
            courses,
            orderPagination,
            errors,
            billingAddresses,
            shippingAddresses,
            updateAddress,
            loginUser,
            getUserDetails,
            getUserGroups,
            getAddresses,
            getOrders,
            getOrganisationDetails,
            addAddress,
            deleteAddress,
            getCoursesOfParticipant,
            setError,
        };
    }
)