export interface UserGroupInterface {
    id: number,
    name: string,
    handle: string,
    description: string
}

export const defaultUserGroups = () => {
    return null;
}
