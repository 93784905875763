<template>
    <div class="w-full">
        <h4 class="heading-4 mb-4 pb-4 border-b border-gray-200">
            Controleer de onderstaande gegevens
        </h4>

        <div class="flex flex-col gap-y-4 w-full">
            <div class="flex flex-col gap-y-2 sm:gap-0">
                <div class="grid sm:grid-cols-2">
                    <span class="font-bold">Cursus:</span>
                    <span class="font-normal">{{ cartStore.cartModalContent.selectedVariant.title }}</span>
                </div>

                <div class="grid sm:grid-cols-2">
                    <span class="font-bold">Startdatum:</span>
                    <span class="font-normal">{{ dayjs(cartStore.cartModalContent.selectedVariant.executionStartDate?.date).format('DD-MM-YYYY') }}</span>
                </div>

                <div class="grid sm:grid-cols-2">
                    <span class="font-bold">Einddatum:</span>
                    <span class="font-normal">{{ dayjs(cartStore.cartModalContent.selectedVariant.executionEndDate?.date).format('DD-MM-YYYY') }}</span>
                </div>

                <div class="grid sm:grid-cols-2">
                    <span class="font-bold">Starttijd:</span>
                    <span class="font-normal">{{ dayjs(cartStore.cartModalContent.selectedVariant.executionStartTime?.date).format('HH:mm') }}</span>
                </div>

                <div class="grid sm:grid-cols-2">
                    <span class="font-bold">Eindtijd:</span>
                    <span class="font-normal">{{ dayjs(cartStore.cartModalContent.selectedVariant.executionEndTime?.date).format('HH:mm') }}</span>
                </div>

                <div class="grid sm:grid-cols-2">
                    <span class="font-bold">Locatie:</span>
                    <span class="font-normal">{{ cartStore.cartModalContent.selectedVariant.executionLocation }}</span>
                </div>

                <div class="grid sm:grid-cols-2">
                    <span class="font-bold">Prijs:</span>
                    <span class="font-normal">
                        {{ cartStore.cartModalContent.selectedVariant.salePriceAsCurrency }}
                        <span v-if="userStore.userIsCommercialCustomer">per medewerker</span>
                    </span>
                </div>
            </div>
        </div>

        <div class="flex flex-col gap-y-4 w-full" v-if="userStore.userIsCommercialCustomer">
            <h4 class="heading-4 py-4 border-y mt-4 border-gray-200">
                Medewerkers ({{ cartStore.cartModalContent.companyUsers?.length }})
            </h4>

            <div class="flex flex-col gap-y-2 sm:gap-0" v-if="cartStore.cartModalContent.companyUsers?.length">
                <template v-for="(selectedCompanyUser) in cartStore.cartModalContent.companyUsers">
                    <div class="grid sm:grid-cols-2">
                        <span class="font-bold">
                            {{ selectedCompanyUser.firstName }} {{ selectedCompanyUser.lastName }}
                        </span>
                        <span class="font-normal">{{ selectedCompanyUser.email }}</span>
                    </div>
                </template>
            </div>

            <div class="flex flex-col" v-if="userStore.organisation?.companyAddress?.street
                        || userStore.organisation?.companyAddress?.houseNumber
                        || userStore.organisation?.companyAddress?.houseNumberAddition
                        || userStore.organisation?.companyAddress?.zipCode
                        || userStore.organisation?.companyAddress?.city
                        || userStore.organisation?.companyAddress?.country
                        || userStore.organisation?.companyAddress?.phone
                        || userStore.organisation?.companyAddress?.emailAddress">
                <h4 class="heading-4 py-4 border-y border-gray-200">
                    Organisatie ({{ userStore.organisation?.name }})
                </h4>

                <div class="flex flex-col gap-y-2 sm:gap-0">
                    <div class="grid sm:grid-cols-2" v-if="userStore.organisation?.companyAddress?.street">
                        <span class="font-bold">Straat:</span>
                        <span class="font-normal">{{ userStore.organisation?.companyAddress?.street }}</span>
                    </div>

                    <div class="grid sm:grid-cols-2" v-if="userStore.organisation?.companyAddress?.houseNumber">
                        <span class="font-bold">Huisnummer:</span>
                        <span class="font-normal">{{ userStore.organisation?.companyAddress?.houseNumber }}</span>
                    </div>

                    <div class="grid sm:grid-cols-2" v-if="userStore.organisation?.companyAddress?.houseNumberAddition">
                        <span class="font-bold">Huisnummer toevoeging:</span>
                        <span class="font-normal">{{ userStore.organisation?.companyAddress?.houseNumberAddition }}</span>
                    </div>

                    <div class="grid sm:grid-cols-2" v-if="userStore.organisation?.companyAddress?.zipCode">
                        <span class="font-bold">Postcode:</span>
                        <span class="font-normal">{{ userStore.organisation?.companyAddress?.zipCode }}</span>
                    </div>

                    <div class="grid sm:grid-cols-2" v-if="userStore.organisation?.companyAddress?.city">
                        <span class="font-bold">Stad:</span>
                        <span class="font-normal">{{ userStore.organisation?.companyAddress?.city }}</span>
                    </div>

                    <div class="grid sm:grid-cols-2" v-if="userStore.organisation?.companyAddress?.country">
                        <span class="font-bold">Land:</span>
                        <span class="font-normal">{{ userStore.organisation?.companyAddress?.country }}</span>
                    </div>

                    <div class="grid sm:grid-cols-2" v-if="userStore.organisation?.companyAddress?.phone">
                        <span class="font-bold">Telefoon:</span>
                        <span class="font-normal">{{ userStore.organisation?.companyAddress?.phone }}</span>
                    </div>

                    <div class="grid sm:grid-cols-2" v-if="userStore.organisation?.companyAddress?.emailAddress">
                        <span class="font-bold">E-mail:</span>
                        <span class="font-normal">{{ userStore.organisation?.companyAddress?.emailAddress }}</span>
                    </div>

                </div>

            </div>
        </div>

        <template v-for="errors in cartStore.errors">
            <template v-for="error in errors">
                <FailureMessage :message="error"/>
            </template>
        </template>

        <div class="flex flex-col items-center gap-2 w-full mt-4 pt-4 border-t border-gray-200 sm:flex-row">
            <button class="button button--secondary w-full sm:w-auto" @click.prevent="closeAndClearCartModal">
                Annuleren
            </button>

            <button class="button button--primary w-full whitespace-nowrap ring-0 ml-auto sm:w-auto" type="button"
                    @click.prevent="userStore.userIsCommercialCustomer ? emit('updateCurrentStep', 3) : emit('updateCurrentStep', 2)">
                Vorige
            </button>

            <button class="button button--primary w-full whitespace-nowrap ring-0 auto sm:w-auto" type="button"
                    @click.prevent="addCourseToCart">
                Toevoegen in winkelwagen
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import {defineEmits, defineProps} from "vue";
import {ShoppingApi} from "../../../Composables/ShoppingApi";
import {useUrlStore} from "../../../Stores/urlStore";
import {useCartStore} from "../../../Stores/cartStore";
import {useUserStore} from "../../../Stores/userStore";
import dayjs from "dayjs";
import FailureMessage from "../../Forms/FailureMessage.vue";

interface PropsInterface {
    currentStep: number,
}

const emit = defineEmits(['updateCurrentStep']);
const {closeAndClearCartModal} = ShoppingApi()
const urlStore = useUrlStore();
const userStore = useUserStore();
const cartStore = useCartStore();
const props = defineProps<PropsInterface>()

const addCourseToCart = () => {
    let qty = 1;
    let options = [];

    const isArticleNumberInCart = Object.values(cartStore.cart.lineItems).some(
        (lineItem: any) => lineItem.articleNumber === cartStore.cartModalContent.selectedVariant.articleNumber
    );

    if( isArticleNumberInCart ) {
        alert('Dit artikel is al toegevoegd in je winkelwagentje');
        throw new Error('Article number is al toegevoegd in je winkelwagentje');
    }

    if (userStore.userIsCommercialCustomer) {
        qty = cartStore.cartModalContent.companyUsers.length
        options = cartStore.cartModalContent.companyUsers;
    }

    try {
        cartStore.addToCart(cartStore.cartModalContent.selectedVariant.id, qty, options)
        closeAndClearCartModal()
        window.location = urlStore.getUrl('cart_url')
    } catch (e) {

    }
}
</script>