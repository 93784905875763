<template>
    <div class="flex flex-col gap-4 w-full">
        <template v-if="!cartStore.cartModalContent.variant.stock">
            De cursus is niet beschikbaar.

            <div class="flex flex-col items-center gap-2 w-full mt-4 pt-4 border-t border-gray-200 sm:flex-row">
                <button class="button button--secondary" @click.prevent="closeAndClearCartModal">
                    Annuleren
                </button>
            </div>
        </template>
        <template v-else>
            <!-- Body -->
            <template v-if="cartStore.cartModalContent.hasExecutionsWithDate">
                <form class="flex flex-col items-start gap-y-4">
                    <h2 class="heading-2">
                        Toevoegen aan je winkelwagen
                    </h2>

                    <div class="flex flex-col items-center gap-4 pb-4 border-b border-gray-200 sm:flex-row">
                        <div class="self-start shrink-0 w-full border border-gray-200 sm:w-24 lg:w-32" v-html="cartStore.cartModalContent.variant.image"/>
                        <div class="flex flex-col w-full sm:gap-2">
                            <h3 v-show="cartStore.cartModalContent.variant.title.length > 0"
                                class="leading-6 font-medium text-lg sm:text-xl"
                                v-text="cartStore.cartModalContent.variant.title">
                            </h3>
                            <span class="text-sm line-clamp-2" v-html="removeHtmlTags(cartStore.cartModalContent.product.shortDescription)"/>
                            <div class="flex items-end gap-3 mt-2">
                                <span class="font-heading font-extrabold text-xl md:text-2xl">
                                    {{ cartStore.cartModalContent.variant.salePriceAsCurrency }}
                                </span>
                                <span v-if="userStore.userIsCommercialCustomer">per medewerker</span>
                            </div>
                        </div>
                    </div>

                    <!-- Tabs -->
                    <modal-course-tabs :currentStep="currentStep"/>

                    <!-- Step 1 -->
                    <modal-course-account v-if="currentStep === 1"
                                          :current-step="currentStep"
                                          @update-current-step="currentStep = $event"
                    />

                    <!-- Step 2 -->
                    <modal-course-step-type v-if="currentStep === 2"
                                            :current-step="currentStep"
                                            @update-current-step="currentStep = $event"
                    />

                    <!-- Step 3 -->
                    <modal-course-participants v-if="currentStep === 3"
                                               :current-step="currentStep"
                                               @update-current-step="currentStep = $event"
                    />

                    <!-- Step 4 -->
                    <modal-course-final
                        v-if="currentStep === 4"
                        :current-step="currentStep"
                        @update-current-step="currentStep = $event"
                    />
                </form>
            </template>

            <template v-else>
                <div class="flex flex-col items-start gap-y-4">
                    <h2 class="heading-2">
                        Startdata
                    </h2>
                    Er zijn geen startdata van deze opleiding bekend. <br>
                    Heeft u interesse in deze opleiding? <br>
                    <a :href="urlStore.getUrl('contact_url')" class="button button--primary button--xl text-base ring-0 mt-4">
                        Neem contact op
                    </a>
                </div>
            </template>
        </template>
    </div>
</template>

<script setup lang="ts">
import {useUserStore} from "../../Stores/userStore";
import {defineProps, ref} from "vue";
import ModalCourseTabs from "./Partials/ModalCourseTabs.vue";
import ModalCourseStepType from "./Partials/ModalCourseStepType.vue";
import ModalCourseAccount from "./Partials/ModalCourseAccount.vue";
import ModalCourseParticipants from "./Partials/ModalCourseParticipants.vue";
import ModalCourseFinal from "./Partials/ModalCourseFinal.vue";
import {ShoppingApi} from "../../Composables/ShoppingApi";
import {useCartStore} from "../../Stores/cartStore";
import {useUrlStore} from "../../Stores/urlStore";
import {useHtmlHelper} from "../../Composables/useHtmlHelper";

const {closeAndClearCartModal} = ShoppingApi()
const urlStore = useUrlStore();
const cartStore = useCartStore();
const {removeHtmlTags} = useHtmlHelper();
interface PropsInterface {
    step: number,
}

const userStore = useUserStore();
const props = defineProps<PropsInterface>()

let currentStep = ref(props.step);
</script>