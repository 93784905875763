<template>
    <div v-if="currentStep === 1" class="w-full">
        <!-- Not logged in -->
        <template v-if="!userStore.user">
            <template v-if="!showRegisterButtons">
                <h4 class="heading-4 mb-4 pb-4 border-b border-gray-200">
                    Inloggen
                </h4>

                <div class="flex flex-col w-full gap-y-4">
                    <div class="flex flex-col w-full gap-y-2">
                        <label for="loginName">E-mailadres</label>
                        <input v-model="loginFields.loginName" required class="w-full" type="email"
                               @keydown.enter.prevent="loginAndUpdateStep"/>
                    </div>
                    <div class="flex flex-col w-full gap-y-2">
                        <label for="password">Wachtwoord</label>
                        <input v-model="loginFields.password" required class="w-full" type="password" autocomplete="current-password"
                               @keydown.enter.prevent="loginAndUpdateStep" />
                    </div>
                </div>

                <FailureMessage :message="userStore.errors?.message"/>

                <div class="w-full mt-4 text-sm text-left italic">
                    Heeft u nog geen account? Klik
                    <button class="text-secondary font-semibold underline underline-offset-2 hover:no-underline"
                            @click.prevent="showRegisterButtons = true"
                    >
                        hier
                    </button>
                    om een account aan te maken.
                </div>

                <a :href="urlStore.getUrl('reset_password_url')" class="inline-flex mt-2 text-sm text-left italic" target="_blank">
                    Wachtwoord vergeten
                </a>

            </template>


            <div class="relative flex flex-col gap-3" v-if="showRegisterButtons">
                <h4 class="heading-4 pb-4 border-b border-gray-200">
                    Account aanmaken
                </h4>

                <p class="w-full text-sm text-left mt-0 mb-4">Wil je je persoonlijk registreren en zelf betalen, of betaalt de werkgever?</p>

                <RegisterContentAndButtons/>

                <div class="w-full mt-2 text-sm text-center italic">
                    Inloggen?
                    <button class="text-secondary font-semibold underline underline-offset-2 hover:no-underline"
                            @click.prevent="showRegisterButtons = false "
                    >
                        Klik hier
                    </button>
                </div>
            </div>

            <div class="flex flex-col items-center gap-2 w-full mt-4 pt-4 border-t border-gray-200 sm:flex-row">
                <button class="button button--secondary w-full whitespace-nowrap ring-0 sm:w-auto" @click.prevent="closeAndClearCartModal">
                    Annuleren
                </button>

                <button v-if="!showRegisterButtons" class="button button--primary w-full whitespace-nowrap ring-0 ml-auto sm:w-auto" type="button"
                        name=""
                        @click.prevent="loginAndUpdateStep">
                    Inloggen
                </button>
            </div>
        </template>

        <template v-if="userStore.user && !userStore.user?.webconnectid">
            <h4 class="heading-4 mb-4 pb-4 border-b border-gray-200">
                Extra gegevens
            </h4>

            <FailureMessage :message="failureMessage"/>

              <div class="flex flex-col w-full gap-y-4">
                <CustomInput v-if="userStore.user.firstName === null"
                             v-model="fields.firstName"
                             :name="'firstName'"
                             :label="'Voornaam'"
                             :errors="errors?.firstName"
                             required
                />

                <CustomInput v-if="userStore.user.lastName === null"
                             v-model="fields.lastName"
                             :name="'lastName'"
                             :label="'Achternaam'"
                             :errors="errors?.lastName"
                             required
                />

                <CustomInput v-model="fields.initials"
                             :name="'initials'"
                             :label="'Initialen'"
                             :errors="errors?.initials"
                />

                <CustomSelect v-model="fields.sex"
                              :name="'sex'"
                              :label="'Geslacht'"
                              :required="true"
                              :options="[
                                                  {
                                                      label: 'Man',
                                                      value: 'M'
                                                  },
                                                  {
                                                      label: 'Vrouw',
                                                      value: 'V'
                                                  },
                                                  {
                                                      label: 'Overige',
                                                      value: 'O'
                                                  }
                                              ]"
                              :errors="errors?.sex"
                />

                <CustomInput v-model="fields.date_of_birth"
                             :name="'date_of_birth'"
                             :type="'date'"
                             :label="'Geboortedatum'"
                             :errors="errors?.date_of_birth"
                />

                <CustomInput v-model="fields.place_of_birth"
                             :name="'place_of_birth'"
                             :label="'Geboorteplaats'"
                             :errors="errors?.place_of_birth"
                />

                <CustomInput v-model="fields.phone_number"
                             :name="'phone_number'"
                             :label="'Telefoonnummer'"
                             :errors="errors?.phone_number"
                />
            </div>

            <div class="flex flex-col items-center gap-2 w-full mt-4 pt-4 border-t border-gray-200 sm:flex-row">
                <button class="button button--secondary w-full whitespace-nowrap ring-0 mr-auto sm:w-auto" @click.prevent="closeAndClearCartModal">
                    Annuleren
                </button>

                <button class="button button--primary w-full whitespace-nowrap ring-0 auto sm:w-auto" type="button"
                        @click.prevent="saveCraftUserToWebConnect">
                    Volgende stap
                </button>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import {defineEmits, defineProps, inject, onMounted, ref} from "vue";
import {useUserStore} from "../../../Stores/userStore";
import {ShoppingApi} from "../../../Composables/ShoppingApi";
import RegisterContentAndButtons from "./RegisterContentAndButtons.vue";
import FailureMessage from "../../Forms/FailureMessage.vue";
import CustomInput from "../../Forms/CustomInput.vue";
import dayjs from "dayjs";
import {useLoading} from "vue-loading-overlay";
import {Axios} from "axios";
import CustomSelect from "../../Forms/CustomSelect.vue";
import {useUrlStore} from "../../../Stores/urlStore";

interface PropsInterface {
    currentStep: number,
}

const loginFields = ref<Object[{
    loginName: string,
    password: string,
}]>([{
    loginName: ' ',
    password: ' ',
}]);

const userStore = useUserStore();
const props = defineProps<PropsInterface>()
const emit = defineEmits(['updateCurrentStep']);
const {closeAndClearCartModal, addToCart} = ShoppingApi()
const showRegisterButtons = ref<boolean>(false);
const $axios: Axios = inject('$axios');
const urlStore = useUrlStore();

const $loading = useLoading({
    // Pass props by their camelCased names
    isFullPage: true,
    canCancel: false, // default false
    color: '#000000',
    loader: 'spinner',
    width: 120,
    height: 120,
    backgroundColor: '#ffffff',
    opacity: 0.5,
    zIndex: 999,
});

onMounted(async () => {
    await userStore.getUserDetails();

    if (userStore.user && userStore.user.webconnectid) {
        emit('updateCurrentStep', 2)
    }
});

/* Login and try to continue */
const loginAndUpdateStep = async () => {
    if (loginFields.value.loginName === undefined | null || loginFields.value.password === undefined | null) {
        userStore.setError('Vul alstublieft uw e-mailadres en wachtwoord in.')

        return;
    }

    const redirectUrl = new URL(window.location.href).href;

    await userStore.loginUser(loginFields.value.loginName, loginFields.value.password, redirectUrl);

    if (userStore.userIsLoggedIn) {
        await userStore.getUserDetails()
        await userStore.getUserGroups()

        if (userStore.user && userStore.user.webconnectid) {
            emit('updateCurrentStep', 2)
        }
    }
}

/* Create WebConnectId with required fields */
interface FieldsInterface {
    firstName: string,
    lastName: string,
    initials: string,
    sex: string,
    date_of_birth: string,
    place_of_birth: string,
    phone_number: string,
}

const defaultFields = (): FieldsInterface => {
    return {
        firstName: userStore.user ? userStore.user.firstName : '',
        lastName: userStore.user ? userStore.user.lastName : '',
        initials: userStore.user ? userStore.user.webconnectInitials : '',
        sex: userStore.user ? userStore.user.webconnectSex : '',
        date_of_birth: userStore.user ? userStore.user.webconnectDateOfBirth?.date ? dayjs(userStore.user.webconnectDateOfBirth?.date).format('YYYY-MM-DD') : '' : '',
        place_of_birth: userStore.user ? userStore.user.webconnectPlaceOfBirth : '',
        phone_number: userStore.user ? userStore.user.webconnectPhoneNumber : '',
    }
}

const fields: ref<FieldsInterface> = ref(defaultFields())
const errors = ref(<FieldsInterface>{});
const failureMessage = ref<string>('');

const saveCraftUserToWebConnect = () => {
    const loader = $loading.show({})

    $axios.post('/actions/_shop-plugin/account/save-craft-user-to-webconnect', {
        fields: fields.value,
    }).then(() => {
        //Refresh user details
        userStore.getUserDetails().finally(() => {
            loader.hide()
            userStore.getUserGroups()

            if (userStore.user && userStore.user.webconnectid) {
                emit('updateCurrentStep', 2)
            }
        });

        failureMessage.value = ''
        errors.value = <FieldsInterface>{}
        fields.value = <FieldsInterface>{}

    }).catch((err) => {
        loader.hide()
        failureMessage.value = err.response.data.message;
        errors.value = err.response.data?.errors;
    });
}
</script>