<template>
    <div class="relative">
        <!-- scrollIntoView has no offset options, scrollInto has offset options but does not work with modals. -->
        <div class="absolute -top-20" ref="scrollTo">
        </div>
        <div v-if="message"
             class="mb-5 mt-5 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mx-auto flex flex-col gap-x-5"
             role="alert">
                <span>
                    {{ message }}
                </span>
        </div>
    </div>
</template>
<script setup lang="ts">

import {defineProps, ref, watch} from 'vue';

interface PropsInterface {
    message: String,
}

const props = defineProps<PropsInterface>();

const scrollTo = ref(null);

watch(() => props.message, (errors) => {
    if (props.message) {
        scrollTo.value.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        })
    }
});
</script>
