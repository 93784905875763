<template>
    <!-- Close button -->
    <button class="absolute top-0 right-0 flex items-center justify-center w-12 h-12 bg-transparent border-none transition-colors hover:text-secondary" @click.prevent="closeAndClearCartModal">
        <icon-close class="w-6 h-6"/>
    </button>
</template>

<script setup lang="ts">
import IconClose from "../../../Icons/IconClose.vue";
import {ShoppingApi} from "../../../Composables/ShoppingApi";

const {closeAndClearCartModal} = ShoppingApi()
</script>