<template>
    <form @submit.prevent="createOrUpdateCompanyUser">
        <div class="mt-10 relative" v-if="successMessage">
            <div class="mb-5 mt-5 text-center bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mx-auto flex flex-col gap-x-5"
                 role="alert">
                {{ successMessage }}
            </div>
        </div>

        <FailureMessage :message="failureMessage"/>

        <div class="text-green-600 border-b border-gray-200 px-6 py-5 last:border-none bg-gray-100">
            <div class="col-span-3 flex flex-col">
                <div class="flex flex-col gap-4 w-full m-0 sm:gap-5 font-medium text-gray-900">
                    <div class="form-group grid gap-4 md:grid-cols-2 md:gap-6">
                        <CustomInput v-model="fields.initials"
                                     :name="'initials'"
                                     :label="'Initialen'"
                                     :errors="errors?.initials"
                        />

                        <CustomInput v-model="fields.first_name"
                                     :name="'first_name'"
                                     :label="'Voornaam'"
                                     :errors="errors?.first_name"
                        />

                        <CustomInput v-model="fields.last_name"
                                     :name="'last_name'"
                                     :label="'Achternaam'"
                                     :errors="errors?.last_name"
                        />

                        <CustomSelect v-model="fields.sex"
                                      :name="'sex'"
                                      :label="'Geslacht'"
                                      :required="true"
                                      :options="[
                                                  {
                                                      label: 'Man',
                                                      value: 'M'
                                                  },
                                                  {
                                                      label: 'Vrouw',
                                                      value: 'V'
                                                  },
                                                  {
                                                      label: 'Overige',
                                                      value: 'O'
                                                  }
                                              ]"
                                      :errors="errors?.sex"
                        />

                        <CustomInput v-model="fields.email_address"
                                     :name="'email_address'"
                                     :label="'E-mail'"
                                     :errors="errors?.email_address"
                        />

                        <CustomInput v-model="fields.date_of_birth"
                                     :name="'date_of_birth'"
                                     :type="'date'"
                                     :label="'Geboortedatum'"
                                     :errors="errors?.date_of_birth"
                        />

                        <CustomInput v-model="fields.place_of_birth"
                                     :name="'place_of_birth'"
                                     :label="'Geboorteplaats'"
                                     :errors="errors?.place_of_birth"
                        />

                        <CustomInput v-model="fields.phone_number"
                                     :name="'phone_number'"
                                     :label="'Telefoonnummer'"
                                     :errors="errors?.phone_number"
                        />

                        <button
                            class="button button-sm button--secondary ml-auto md:col-start-2"
                        >
                            Opslaan
                        </button>

                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script setup lang="ts">
import {defineEmits, defineProps, inject, ref} from "vue";
import {OrganisationUserInterface} from "../../Interfaces/OrganisationUserInterface";
import CustomInput from "../Forms/CustomInput.vue";
import {Axios} from "axios";
import dayjs from "dayjs";
import FailureMessage from "../Forms/FailureMessage.vue";
import {useUserStore} from "../../Stores/userStore";
import {useLoading} from "vue-loading-overlay";
import {isObject} from "@vueuse/core";
import CustomSelect from "../Forms/CustomSelect.vue";

const userStore = useUserStore()

interface PropsInterface {
    user?: OrganisationUserInterface | null,
}

const props = withDefaults(defineProps<PropsInterface>(), {
    user: null,
});

interface FieldsInterface {
    initials: string,
    first_name: string,
    last_name: string,
    sex: string,
    email_address: string,
    date_of_birth: string,
    place_of_birth: string,
    phone_number: string,
}

const defaultFields = (): FieldsInterface => {
    return {
        initials: props.user ? props.user.webconnectInitials : '',
        first_name: props.user ? props.user.firstName : '',
        last_name: props.user ? props.user.lastName : '',
        sex: props.user ? props.user.webconnectSex : '',
        email_address: props.user ? props.user.email : '',
        date_of_birth: props.user ? dayjs(props.user.webconnectDateOfBirth?.date).format('YYYY-MM-DD') : '',
        place_of_birth: props.user ? props.user.webconnectPlaceOfBirth : '',
        phone_number: props.user ? props.user.webconnectPhoneNumber : '',
    }
}

const fields: ref<FieldsInterface> = ref(defaultFields())
const errors = ref(<FieldsInterface>{});
const failureMessage = ref<String>('');
const successMessage = ref<String>('');
const $axios: Axios = inject('$axios');

const $loading = useLoading({
    // Pass props by their camelCased names
    isFullPage: true,
    canCancel: false, // default false
    color: '#000000',
    loader: 'spinner',
    width: 120,
    height: 120,
    backgroundColor: '#ffffff',
    opacity: 0.5,
    zIndex: 999,
});

const emit = defineEmits(['companyUserAddedOrUpdated']);

function createOrUpdateCompanyUser() {
    const loader = $loading.show({})

    // Get company info from user
    $axios.post('/actions/_shop-plugin/business-account/create-or-update-company-user', {
        organisationWebConnectId: userStore.organisation.webConnectId,
        userWebConnectId: props.user ? props.user.webconnectid : null,
        fields: fields.value,
    }).then((res) => {
        emit('companyUserAddedOrUpdated', res.data.newOrCurrentCompanyUser)

        //Refresh organisation details
        userStore.getOrganisationDetails().finally(() => {
            loader.hide()
        });

        console.debug('response quote with values', res.data);
        errors.value = <FieldsInterface>{};
        failureMessage.value = '';
        successMessage.value = res.data.message;

        // reset fields when successfully added a new user
        if (!isObject(props.user)) {
            fields.value = <FieldsInterface>{};
        }
    }).catch((err) => {
        loader.hide()
        failureMessage.value = err.response.data.message;
        successMessage.value = '';
        errors.value = err.response.data.errors;
    });
}
</script>